<template>
  <div class="signature">
    <canvas id="signature-canvas"></canvas>
    <div class="placeholder"><span>签</span><span>字</span><span>区</span></div>
    <div class="btns">
      <button class="btn" @click="redo">撤销</button>
      <button class="btn" @click="clear">清除</button>
      <button class="btn" @click="$router.go(-1)">取消</button>
      <button class="btn" @click="confirm">确定</button>
    </div>
  </div>
</template>
<script>
import SmoothSignature from "./smoothSignature";

export default {
  name: "signature",
  data() {
    return {
      signature: null,
    }
  },
  mounted() {
    this.$toast('请横屏书写')
    let canvas = document.getElementById('signature-canvas');

    const options = {
      width: window.innerWidth - 60,
      height: window.innerHeight - 30,
      minWidth: 8,
      maxWidth: 15,
      // color: "#1890ff",
      bgColor: 'transparent',
      // bgColor: '#eee',
    };
    this.signature = new SmoothSignature(canvas, options);
  },
  methods: {
    redo() {
      this.signature.undo();
    },
    clear() {
      this.signature.clear();
    },
    confirm() {
      const isEmpty = this.signature.isEmpty();
      if (isEmpty) {
        this.$toast('请完成签名')
        return;
      }
      const pngUrl = this.signature.getPNG();
      sessionStorage.setItem('signature'+this.$route.query.type,pngUrl)
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>
.signature {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
#signature-canvas{
  border: 4px dashed #aaa;
  border-radius: 10px;
  margin-left: 90px;
  margin-top: 30px;
}
.btns {
  position: absolute;
  width: 100vh;
  height: 60px;
  line-height: 60px;
  top: 0;
  left: 0;
  transform-origin: 0 60px;
  transform: rotate(90deg) translate(-30px,-15px);
  display: flex;
}
.btn {
  margin-right: 30px;
  border-radius: 10px;
  background: #577BB5;
  color: #fff;
  width: 150px;
  text-align: center;
}
.placeholder {
  position: absolute;
  width: 100vh;
  text-align: center;
  top: 50%;
  left: calc(50% + 40px);
  transform: translate(-50%, -50%) rotate(90deg);
  pointer-events: none;
  font-size: 300px;
  white-space: nowrap;
  opacity: 0.04;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5em;
}
</style>
